<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre de Administradora *" />
      <BorderInput
        v-model="company.data.name"
        label="Nombre de Administradora *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="company.rules.name"
        message="Ingrese un nombre sin caracteres raros"
      />
      <BasicLabel label="Tipo de Documento *" />
      <BorderSelect
        v-model="company.data.identityTypeId"
        label="Tipo de Documento *"
        :options="store.state.general.identityType"
      />
      <FormError
        :show="company.rules.identityTypeId"
        message="Seleccione un tipo de documento"
      />
      <BasicLabel label="Rut / CI *" />
      <BorderInput
        v-model="company.data.identity"
        label="Rut / CI *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="company.rules.identity"
        message="Ingrese un documento válido sin espacios, puntos y guiones"
      />
      <BasicLabel v-if="false" label="País *" />
      <BorderSelect
        v-if="false"
        v-model="company.data.countryId"
        label="País *"
        :options="store.state.general.countries"
      />
      <FormError :show="company.rules.countryId" message="Seleccione un país" />
      <BasicLabel label="Departamento *" />
      <BorderSelect
        v-model="company.data.stateId"
        label="Departamento *"
        :options="
          store.state.general.states.filter(
            (item) => item.countryId === company.data.countryId
          )
        "
        :disabled="!company.data.countryId"
      />
      <FormError
        :show="company.rules.stateId"
        message="Seleccione un departamento"
      />
      <BasicLabel label="Ciudad *" />
      <BorderSelect
        v-model="company.data.cityId"
        label="Ciudad *"
        :options="
          store.state.general.cities.filter(
            (item) => item.stateId === company.data.stateId
          )
        "
        :disabled="!company.data.stateId"
      />
      <FormError :show="company.rules.cityId" message="Seleccione una ciudad" />
      <BasicLabel label="Dirección *" />
      <BorderInput
        v-model="company.data.address"
        label="Dirección *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="company.rules.address"
        message="Ingrese una dirección"
      />
      <BasicLabel label="Email *" />
      <BorderInput
        v-model="company.data.email"
        label="Email *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="company.rules.email"
        message="Ingrese un email válido"
      />
      <BasicLabel label="Teléfono *" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="company.data.phone"
          label="Teléfono *"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="company.rules.phone"
        message="Ingrese un teléfono válido"
      />
      <BasicLabel v-if="false" label="Tipo de Plan *" />
      <BorderSelect
        v-if="false"
        v-model="company.data.planId"
        label="Tipo de Plan *"
        :options="store.state.general.plans"
      />
      <FormError :show="company.rules.planId" message="Seleccione un plan" />

      <BorderFile
        :v-model="company.selectedDocuments"
        :multiple="false"
        placeholder="Arrastre o agregue su logo en jpg o png"
        accept="image/*"
        @select="onSelectFiles"
      />
      <FormError />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
    BorderFile,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const company = reactive({
      selectedDocuments: [],
      data: {
        id: -1,
        name: "",
        identityTypeId: "",
        identity: "",
        address: "",
        countryId:
          store.state.general.countries.length === 1
            ? store.state.general.countries[0].id
            : 1,
        stateId: "",
        cityId: "",
        email: "",
        phone: "",
        logo: "",
        planId: 1,
      },
      rules: {
        name: false,
        identityTypeId: false,
        identity: false,
        address: false,
        countryId: false,
        stateId: false,
        cityId: false,
        email: false,
        phone: false,
        planId: false,
      },
    });

    const onSelectFiles = (ev) => {
      company.selectedDocuments = ev.files;
    };

    const validate = () => {
      let valid = true;

      if (
        !company.data.name ||
        !validation.characterRegex.test(company.data.name)
      ) {
        company.rules.name = true;
        valid = false;
      }
      if (!company.data.identityTypeId) {
        company.rules.identityTypeId = true;
        valid = false;
      }
      if (
        !company.data.identity ||
        !company.data.identityTypeId ||
        company.data.identityTypeId === 1
          ? !validation.validateRUT(company.data.identity)
          : !validation.validateCI(company.data.identity)
      ) {
        company.rules.identity = true;
        valid = false;
      }
      if (!company.data.address) {
        company.rules.address = true;
        valid = false;
      }
      if (!company.data.countryId) {
        company.rules.countryId = true;
        valid = false;
      }
      if (!company.data.stateId) {
        company.rules.stateId = true;
        valid = false;
      }
      if (!company.data.cityId) {
        company.rules.cityId = true;
        valid = false;
      }
      if (
        !company.data.email ||
        !validation.emailRegex.test(company.data.email)
      ) {
        company.rules.email = true;
        valid = false;
      }
      if (
        !company.data.phone ||
        !validation.phoneRegex.test(company.data.phone)
      ) {
        company.rules.phone = true;
        valid = false;
      }
      if (!company.data.planId) {
        company.rules.planId = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      let key = null;
      const uploaded = async () => {
        const request = {
          id: company.data.id,
          name: company.data.name,
          identityTypeId: company.data.identityTypeId,
          identity: company.data.identity,
          address: company.data.address,
          countryId: company.data.countryId,
          stateId: company.data.stateId,
          cityId: company.data.cityId,
          email: company.data.email,
          phone: `+598 ${company.data.phone}`,
          logo: key,
          planId: company.data.planId,
        };

        let response = null;
        if (store.state.openMode === openMode.MODIFY) {
          response = await store.dispatch(
            actions.companyActions.update,
            request
          );
        } else {
          response = await store.dispatch(
            actions.companyActions.create,
            request
          );
        }
        store.commit("setLoading", false);
        if (response.ok) {
          store.commit("setSelectedCompanies", []);
          router.push("/administradoras/listado");
        } else {
          alert(response.message);
        }
      };

      if (company.selectedDocuments.length) {
        const ext = validation.extensionRegex.exec(
          company.selectedDocuments[0].name
        )[0];
        const name = `${company.data.name}${ext}`;
        key = `${company.data.name}/images/${name}`;
        aws.uploadFile(key, company.selectedDocuments[0], uploaded);
      } else {
        uploaded();
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/administradoras/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.company.selectedCompanies[0];
        company.data.id = selected.id;
        company.data.name = selected.name;
        company.data.identityTypeId = selected.identityTypeId;
        company.data.identity = selected.identity;
        company.data.address = selected.address;
        company.data.countryId = selected.countryId;
        company.data.stateId = selected.stateId;
        company.data.cityId = selected.cityId;
        company.data.email = selected.email;
        company.data.phone = selected.phone ? selected.phone.split(" ")[1] : "";
        company.data.logo = selected.logo;
        company.data.planId = selected.planId;
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (
        company.data.name &&
        validation.characterRegex.test(company.data.name)
      )
        company.rules.name = false;
      if (company.data.identityTypeId) company.rules.identityTypeId = false;
      if (
        company.data.identity &&
        company.identityTypeId &&
        company.data.identityTypeId === 1
          ? validation.validateRUT(company.data.identity)
          : validation.validateCI(company.data.identity)
      )
        company.rules.identity = false;
      if (company.data.address) company.rules.address = false;
      if (company.data.countryId) company.rules.countryId = false;
      if (company.data.stateId) company.rules.stateId = false;
      if (company.data.cityId) company.rules.cityId = false;
      if (company.data.email && validation.emailRegex.test(company.data.email))
        company.rules.email = false;
      if (company.data.phone && validation.phoneRegex.test(company.data.phone))
        company.rules.phone = false;
      if (company.data.planId) company.rules.planId = false;
    });

    return { store, company, onSave, onSelectFiles };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
